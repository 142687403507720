import { defineStore } from 'pinia'
import type * as appTypes from '~/ts/types/app'
import type { AnyObj } from '~/ts/types/common'
import type { FormRadioOption, FormSelectOption } from '~/ts/types/form'
import { AppItemRoleEnum, AppItemPhoneCountryIndexEnum } from '~/ts/enums/app'

type SettingState = {
    roles: appTypes.AppItemRole[]
    timezones: appTypes.AppItemTimezone[]
    phoneCountries: appTypes.AppItemPhoneCountry[]
    phonePreferredCountries: appTypes.AppItemPhoneCountry[]
    widgetConfig: {
        empty?: boolean
        widget_theme: AnyObj[]
        widget_position: string[]
        widget_icon: string[]
        widget_button_shape: string[]
    }
}

export const useSettingStore = defineStore('settingStore', {
    state: (): SettingState => ({
        roles: [
            { key: 'administrator', value: AppItemRoleEnum.Admin },
            { key: 'operator', value: AppItemRoleEnum.Operator }
        ],
        timezones: [],
        phoneCountries: [],
        phonePreferredCountries: [],
        widgetConfig: {
            empty: true,
            widget_theme: [],
            widget_position: [],
            widget_icon: [],
            widget_button_shape: []
        }
    }),
    getters: {
        roleOptions: (state: SettingState): FormRadioOption[] => {
            const roleOptions: FormRadioOption[] = []

            for (const index in state.roles) {
                roleOptions.push({
                    value: state.roles[index].value,
                    label: state.roles[index].key
                })
            }

            return roleOptions
        },
        roleOptionValues(): number[] {
            return this.roleOptions.map(v => v.value)
        },
        roleKeysByValue(state: SettingState): Record<number, string> {
            const roleKeysByValue = {} as Record<number, string>

            for (const index in state.roles) {
                roleKeysByValue[state.roles[index].value] = state.roles[index].key
            }

            return roleKeysByValue
        },
        timezoneOptions: (state: SettingState): FormSelectOption[] => {
            const timezoneOptions: FormSelectOption[] = []

            for (const index in state.timezones) {
                timezoneOptions.push({
                    value: state.timezones[index].timezone,
                    text: state.timezones[index].label
                })
            }

            return timezoneOptions
        },
        timezoneOptionValues(): string[] {
            return this.timezoneOptions.map(v => v.value)
        },
        widgetConfigOptions: (state: SettingState): Record<string, FormSelectOption[]> => {
            const options: any = {
                widget_theme: [],
                widget_icon: [],
                widget_position: [],
                widget_button_shape: []
            }

            for (const index in state.widgetConfig.widget_theme) {
                options.widget_theme.push({
                    value: state.widgetConfig.widget_theme[index].value,
                    text: state.widgetConfig.widget_theme[index].bg
                })
            }

            for (const key of [ 'widget_position', 'widget_icon', 'widget_button_shape' ]) {
                for (const index in state.widgetConfig[key]) {
                    options[key].push({
                        value: state.widgetConfig[key][index].value,
                        text: state.widgetConfig[key][index].name
                    })
                }
            }

            return options
        },
        widgetConfigOptionValues(): Record<string, string[] | number[]> {
            return {
                widget_theme: this.widgetConfigOptions.widget_theme.map(v => v.value),
                widget_icon: this.widgetConfigOptions.widget_icon.map(v => v.value),
                widget_position: this.widgetConfigOptions.widget_position.map(v => v.value),
                widget_button_shape: this.widgetConfigOptions.widget_button_shape.map(v => v.value)
            }
        },
        widgetConfigThemeColorsByValue(): Record<string, any> {
            return this.widgetConfig.widget_theme
                .reduce((result, item) => {
                    result[item.value] = item

                    return result
                }, {})
        },
        phoneCountryOptions: (state: SettingState): FormSelectOption[] => {
            const phoneCountryOptions: FormSelectOption[] = []

            for (const index in state.phoneCountries) {
                phoneCountryOptions.push({
                    _extra: { searchValue: state.phoneCountries[index][2] },
                    value: state.phoneCountries[index],
                    text: state.phoneCountries[index][0]
                })
            }

            return phoneCountryOptions
        },
        phoneCountryPreferredOptions: (state: SettingState): FormSelectOption[] => {
            const phoneCountryOptions: FormSelectOption[] = []

            for (const index in state.phonePreferredCountries) {
                phoneCountryOptions.push({
                    _extra: { searchValue: state.phoneCountries[index][2] },
                    value: state.phonePreferredCountries[index],
                    text: state.phonePreferredCountries[index][0]
                })
            }

            return phoneCountryOptions
        },
        phoneCountriesByCode: (state: SettingState): Record<string, appTypes.AppItemPhoneCountry> => {
            return state.phoneCountries.reduce((result, value) => {
                result[value[AppItemPhoneCountryIndexEnum.Code]] = value

                return result
            }, {})
        }
    },
    actions: {
        async fillTimezones(): Promise<void> {
            if (this.timezones.length) {
                return
            }

            const { data, error, pending, execute } = await useApi().setting.getTimezones()

            // FIXME запит може не очікуватись через те, що ця сторінка може бути в ClientOnly (наприклад, через неможливість виявлення розміру екрану на сервері під час рендерингу)
            if (pending.value) {
                await execute()
            }

            if (error.value) {
                throw error.value
            }

            this.timezones = data.value
        },
        async fillPhoneCountries(): Promise<void> {
            if (this.phoneCountries.length) {
                return
            }

            this.phoneCountries = (await import('~/assets/json/phone-countries.json')).default as any
            this.phonePreferredCountries = (await import('~/assets/json/phone-countries-preferred.json')).default as any
        },
        async fillWidgetConfig(): Promise<void> {
            if (!this.widgetConfig.empty) {
                return
            }

            const { data, error, pending, execute } = await useApi().setting.getWidgetConfig()

            // FIXME запит може не очікуватись через те, що ця сторінка може бути в ClientOnly (наприклад, через неможливість виявлення розміру екрану на сервері під час рендерингу)
            if (pending.value) {
                await execute()
            }

            if (error.value) {
                throw error.value
            }

            this.widgetConfig = data.value
        }
    }
})
